import React from 'react';
import { Link } from 'gatsby';

const Footer = class extends React.Component {
  render() {
    return (
      <footer className='footer has-background-black'>
        <div className='content has-text-centered has-background-black'>
          <div className='container has-background-black'>
            <span className='is-uppercase is-size-7 has-text-weight-light'>
              © 2020 Devoom | Tous droits réservés | Site réalisé par{' '}
              <Link to='/' title='Home'>
                Devoom
              </Link>{' '}
              |{' '}
              <Link to='/mentions-legales' title='Mentions légales'>
                Mentions légales
              </Link>
            </span>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
